<template>
  <Layout>
    <div class="row align-items-center">
      <div class="col-sm-6">
        <div class="page-title-box">
          <h4 class="font-size-18">Dashboard</h4>
          <ol class="breadcrumb mb-0">
            <li class="breadcrumb-item active">Welcome to ANYTIME WORK Dashboard</li>
          </ol>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-3 col-md-6" v-for="stat of statData" :key="stat.icon">
        <Stat
          :title="stat.title"
          :image="stat.image"
          :value="stats(stat.value)"
        />
      </div>
    </div>
    <div class="row">
                    <div class="col-sm-6 col-md-3">
              <div class="col-sm-10">
                <select class="form-control" @change="getData($event)">
                  <option selected hidden>Select Type</option>
                  <option value="2">Employee</option>
                  <option value="3">Employer</option>
                </select>
              </div>
            </div>

    <div id="chart">
        <apexchart type="bar" height="350" width="1000" :options="chartOptions" :series="series"></apexchart>
    </div>
</div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import Stat from "@/components/widgets/widget-stat";
import { dashboardMethods } from "@/state/helpers";
export default {
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    Stat,
  },
  data() {
    return {
      series: [{
        data:[]
        }],
      chartOptions: {
        chart: {
            type: 'bar',
            height: 350
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '100%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        },
        yaxis: {
            title: {
                text: ''
            }
        },
        fill: {
            opacity: 1,
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val + ""
                }
            },
        }
      },
      statData: [
        {
          title: "Total Employees",
          image: require("@/assets/images/employees.png"),
          value: "total_employees"
        },
        {
          title: "Total Employers",
          image: require("@/assets/images/employer.png"),
          value: "total_employers",
        },
        {
          title: "Total Jobs",
          image: require("@/assets/images/jobs.png"),
          value: "total_jobs",
        },
        {
          title: "Total Active Jobs",
          image: require("@/assets/images/active-task.png"),
          value: "total_active_jobs",
        },
        {
          title: "Total Completed Jobs",
          image: require("@/assets/images/completed-job.png"),
          value: "total_completed_jobs",
        },
        {
          title: "Total Cancelled Jobs",
          image: require("@/assets/images/error.png"),
          value: "total_cancelled_jobs",
        },

      ],
    };
  },
  computed: {
  },
  methods: {
    ...dashboardMethods,
    getStats()
    {
      this.$store.dispatch('dashboard/getAllStats').then(res=>{
        console.log("res",res);
      }).catch(error=>{
        this.unauthorizedError(error)
      });
    },
    unauthorizedError(err){
      if (err.data.status === 401) {
        console.log("error",err);
        return this.$router.push('/logout');
      }
    },
    stats(value){
        return this.$store.state.dashboard.stats[value]
    },
    getData(event){
      return(
      this.getGraphData({type:event.target.value}).then( ()=>
      {
        this.series = [{
        // data: res.data.data
        data: this.$store.state.dashboard.graph.data
        }];
        this.chartOptions={
        yaxis:{
          title:{
            text:event.target.options[event.target.options.selectedIndex].text
              }
            }
          }
      }
      )
      );
    },
  },
  mounted() {
    this.getStats()
  },
  created(){}
};
</script>
